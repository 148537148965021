import { createContext, useCallback, useState } from "react";
import {
  deleteCocktailById,
  getAllCocktails,
  getSearchCocktailsByKeyword,
  postCocktail,
  putCocktailById,
} from "../clients/backendClient";
import { useConfigContext, useTokenContext } from "../hooks/context";

const CocktailsContext = createContext([]);

const CocktailsProvider = ({ children }) => {
  const [cocktails, setCocktails] = useState([]);
  const [cocktailsPartialList, setCocktailsPartialList] = useState(false);
  const { config } = useConfigContext();
  const { token } = useTokenContext();

  const fetchCocktails = useCallback(async () => {
    if (config.showCocktails) {
      console.log("fetchCocktails");
      const results = await getAllCocktails();
      setCocktails(results);
      setCocktailsPartialList(false);
    }
  }, [config.showCocktails]);

  const getCocktails = async () => {
    console.log("getCocktails");
    const results = await getAllCocktails();
    setCocktails(results);
    setCocktailsPartialList(false);
  };

  const createCocktail = (cocktail) => {
    console.log("createCocktail");
    const cocktailWithId = { id: cocktails.length + 1, ...cocktail };
    setCocktails([...cocktails, cocktailWithId]);
    postCocktail(cocktailWithId, token);
  };

  const updateCocktail = (id, newCocktail) => {
    console.log("updateCocktail");
    const updatedCocktails = cocktails.map((cocktail) => {
      if (cocktail.id === id) {
        // We'll want this loaded up with all the newest stuff, so it can do a full replace.
        return newCocktail;
      }
      return cocktail;
    });
    setCocktails(updatedCocktails);
    putCocktailById(id, newCocktail, token);
  };

  const deleteCocktail = (id) => {
    console.log("deleteCocktail");
    const updatedCocktails = cocktails.filter((cocktail) => {
      return cocktail.id !== id;
    });
    setCocktails(updatedCocktails);
    deleteCocktailById(id, token);
  };

  const updateCocktailValueById = (id, key, value) => {
    console.log("updateCocktailValueById");
    const updatedCocktails = cocktails.map((cocktail) => {
      if (cocktail.id === id) {
        let updatedCocktail = cocktail;
        updatedCocktail[key] = value;
        putCocktailById(id, updatedCocktail, token);
        return updatedCocktail;
      }
      return cocktail;
    });
    setCocktails(updatedCocktails);
  };

  const searchCocktailsByKeyword = async (keyword) => {
    console.log("searchCocktailsByKeyword");
    const results = await getSearchCocktailsByKeyword(keyword);
    setCocktails(results);
    setCocktailsPartialList(true);
  };

  const contextValue = {
    cocktails,
    cocktailsPartialList,
    fetchCocktails,
    getCocktails,
    createCocktail,
    updateCocktail,
    deleteCocktail,
    updateCocktailValueById,
    searchCocktailsByKeyword,
  };
  return (
    <CocktailsContext.Provider value={contextValue}>
      {children}
    </CocktailsContext.Provider>
  );
};

export { CocktailsProvider };
export default CocktailsContext;
