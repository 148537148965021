import axios from "axios";
import {
  backendBaseUrl,
  defaultEmailPublicKey,
  defaultEmailTemplateId,
} from "../config";

const generateRequestConfig = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

// --- CONFIG ---
export const getConfig = async () => {
  const conf = await axios
    .get(`${backendBaseUrl}/configuration`)
    .then((response) => {
      return {
        authPublicKey: response.data.authPublicKey,
        showCocktails: response.data.showCocktails,
        emailPubKey: response.data.emailPubKey,
        emailTemplateId: response.data.emailTemplateId,
      };
    })
    .catch(() => {
      return {
        authPublicKey: undefined,
        showCocktails: false,
        emailPubKey: defaultEmailPublicKey,
        emailTemplateId: defaultEmailTemplateId,
      };
    });
  return conf;
};

// --- TOKEN ---
export const postAuth = async (loginData) => {
  const response = await axios.post(`${backendBaseUrl}/auth`, loginData);
  return {
    authToken: response.data.authToken,
    expirationTime: response.data.expirationTime,
  };
};

// --- COCKTAILS ---

export const getAllCocktails = async () => {
  const response = await axios
    .get(`${backendBaseUrl}/cocktails`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
  return response;
};

export const postCocktail = async (cocktail, token) => {
  const response = await axios.post(
    `${backendBaseUrl}/cocktails`,
    cocktail,
    generateRequestConfig(token)
  );
  return response.data;
};

export const putCocktailById = async (id, cocktail, token) => {
  const response = await axios.put(
    `${backendBaseUrl}/cocktails/${id}`,
    cocktail,
    generateRequestConfig(token)
  );
  return response.data;
};

export const deleteCocktailById = async (id, token) => {
  const response = await axios.delete(
    `${backendBaseUrl}/cocktails/${id}`,
    generateRequestConfig(token)
  );
  return response.data;
};

export const getSearchCocktailsByKeyword = async (keyword) => {
  const response = await axios
    .get(`${backendBaseUrl}/cocktails/search/keyword/${keyword}`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
  return response;
};
