import { v4 as uuidv4 } from "uuid";
import { IoSearch } from "react-icons/io5";
import { GoChevronDown, GoChevronLeft } from "react-icons/go";
import { useEffect, useState } from "react";
import { useCocktailsContext } from "../hooks/context";
import { GLASSWARE_MAPPING } from "../models";
import { HiOutlineMagnifyingGlassCircle } from "react-icons/hi2";

const CocktailsPage = () => {
  const {
    cocktails,
    cocktailsPartialList,
    getCocktails,
    searchCocktailsByKeyword,
  } = useCocktailsContext();
  const [openIndex, setOpenIndex] = useState(-1);

  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [clearKeyword, setClearKeyword] = useState(false);
  const [submittedKeyword, setSubmittedKeyword] = useState("");
  const [fetchAllCocktails, setFetchAllCocktails] = useState(false);

  const [showIngredientNotes, setShowIngredientNotes] = useState(false);

  useEffect(() => {
    if (submittedKeyword) {
      searchCocktailsByKeyword(submittedKeyword);
      setSubmittedKeyword("");
    }
  }, [searchCocktailsByKeyword, submittedKeyword]);

  useEffect(() => {
    if (fetchAllCocktails) {
      getCocktails();
      setFetchAllCocktails(false);
    }
  }, [getCocktails, fetchAllCocktails]);

  const renderKeywords = (key, cocktail) => {
    let count = 0;
    return (
      <ul className="cocktails-keywords-list">
        {cocktail[key].map((entry) => {
          if (count >= 4) {
            return undefined;
          }
          count++;
          entry = entry.trim();
          return (
            <li key={uuidv4()} className="cocktails-keyword">
              {entry}
            </li>
          );
        })}
      </ul>
    );
  };

  const handleSearchClick = () => {
    setSubmittedKeyword("");
    setOpenSearchBar(!openSearchBar);
  };

  const handleClick = (index) => {
    // If you click on the same cocktail that's already open, close it.
    if (openIndex === index) {
      setOpenIndex(-1);
      return;
    }
    setOpenIndex(index);
  };

  const handleIngredientNotesClick = () => {
    setShowIngredientNotes(!showIngredientNotes);
  };

  const renderStringToListVertical = (key, cocktail) => {
    const formattedSteps = cocktail[key].split("\n").map((entry) => {
      entry = entry.trim();
      entry = entry.charAt(0).toUpperCase() + entry.slice(1);
      if (!entry.endsWith(".")) {
        entry += ".";
      }
      return entry;
    });
    return formattedSteps.map((step) => {
      return <li key={uuidv4()}>{step}</li>;
    });
  };

  const renderedCocktails = cocktails.map((cocktail, index) => {
    const isSelected = openIndex === index;
    const carrot = isSelected ? (
      <GoChevronDown className="cocktails-expansion-carrot" />
    ) : (
      <GoChevronLeft className="cocktails-expansion-carrot" />
    );
    const image = cocktail.image ? (
      <img src={cocktail.image} alt="cocktail" />
    ) : undefined;

    const recipe = () => {
      if (!isSelected) {
        return;
      }
      return (
        <div key={uuidv4()} className="cocktails-recipe">
          {cocktail.ingredients.map((ingredient) => {
            return (
              <div key={uuidv4()} className="cocktails-ingredient">
                <div>{ingredient.amount}</div>
                <div>{ingredient.unit}</div>
                <p className="cocktails-ingredient-spacer">-</p>
                <div className="cocktails-ingredient-name-wrapper">
                  <div>
                    {ingredient.homemade ? <sup>*</sup> : undefined}
                    {ingredient.name}
                  </div>
                  {ingredient.notes && showIngredientNotes ? (
                    <div>({ingredient.notes})</div>
                  ) : undefined}
                </div>
              </div>
            );
          })}
          <ol className="cocktails-recipe-steps">
            {renderStringToListVertical("steps", cocktail)}
          </ol>
        </div>
      );
    };
    const notesButton = (
      <button
        className="general-button button-filled cocktails-notes-button"
        onClick={handleIngredientNotesClick}
      >
        <HiOutlineMagnifyingGlassCircle className="cocktails-bottom-row-icon cocktails-bottom-row-btn-icon" />
      </button>
    );
    return (
      <div key={uuidv4()} className="cocktails-wrapper">
        <div
          className="cocktails-header"
          onClick={() => {
            handleClick(index);
          }}
        >
          <div className="cocktails-title">{cocktail.name}</div>
          {carrot}
        </div>
        <div
          className="cocktails-content"
          onClick={() => {
            handleClick(index);
          }}
        >
          {renderKeywords("keywords", cocktail)}
          {image}
          {recipe()}
        </div>
        <div className="cocktails-content-bottom-row">
          {GLASSWARE_MAPPING[cocktail.glassware]}
          {isSelected ? notesButton : undefined}
        </div>
      </div>
    );
  });

  const onSearchSubmit = (event) => {
    event.preventDefault();
    setClearKeyword(true);
    setSubmittedKeyword(searchKeyword);
  };

  const handleSearchKeywordChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleSearchClear = () => {
    if (clearKeyword) {
      setSearchKeyword("");
      setClearKeyword(false);
    }
  };

  const renderSearchBar = () => {
    if (!openSearchBar) {
      return;
    }
    return (
      <form className="cocktails-search-bar" onSubmit={onSearchSubmit}>
        <label className="cocktails-search-bar-label">Search:</label>
        <input
          className="cocktails-search-bar-text-input"
          type="text"
          name="search-keyword"
          placeholder="Seek and ye shall find!"
          onFocus={handleSearchClear}
          onChange={handleSearchKeywordChange}
          value={searchKeyword}
        />
        <button className="general-button cocktails-search-bar-button">
          Search
        </button>
      </form>
    );
  };

  const renderResetButton = () => {
    if (!cocktailsPartialList) {
      return;
    }
    return (
      <button
        className="general-button cocktails-search-bar-reset-button"
        onClick={() => {
          setFetchAllCocktails(true);
        }}
      >
        Reset
      </button>
    );
  };

  return (
    <div className="outer-wrapper cocktails-adjusted-outer-wrapper">
      <div className="cocktails-search-wrapper">
        <IoSearch
          className="general-button cocktails-search-bar-icon"
          onClick={handleSearchClick}
        />
        {renderResetButton()}
        {renderSearchBar()}
      </div>
      {renderedCocktails}
      <div className="cocktails-legend">
        <sup>*</sup> Means homemade
      </div>
    </div>
  );
};

export default CocktailsPage;
