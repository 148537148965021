const AdminCocktailTextToListEdit = ({
  title,
  cocktailField,
  existingValue,
  handleChange,
  delimiter,
}) => {
  return (
    <div className="admin-cocktails-edit-field-wide">
      <label className="admin-cocktails-edit-label general-small-bottom-space admin-cocktails-edit-steps">
        {title}
      </label>
      <textarea
        className="admin-cocktails-edit-text-area"
        placeholder={`${title} - ${
          delimiter ? delimiter : "new line"
        } delimited`}
        name={cocktailField}
        value={existingValue}
        onChange={(event) => {
          handleChange(cocktailField, event.target.value);
        }}
      />
    </div>
  );
};

export default AdminCocktailTextToListEdit;
