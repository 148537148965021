import { useEffect, useState } from "react";
import { useCocktailsContext } from "../hooks/context";
import AdminCocktailTextToListEdit from "./AdminCocktailTextToListEdit";
import AdminCocktailIngredientEditV2 from "./AdminCocktailIngredientEditV2";
import { EMPTY_COCKTAIL, GLASSWARE } from "../models";
import Dropdown from "./Dropdown";

const AdminCocktailEdit = ({ incomingCocktail, closeEdit }) => {
  const { createCocktail, updateCocktail } = useCocktailsContext();
  const [cocktail, setCocktail] = useState(incomingCocktail);

  const handleClear = () => {
    // We operate with the keywords as a string in here, then we'll split them into a list at the end.  So - if
    // we're in here and we're clearing, we can reset this to an string and lean on the submission pattern to
    // sort it for outside of this.
    setCocktail({ id: cocktail.id, keywords: "", ...EMPTY_COCKTAIL });
  };

  const handleChange = (event) => {
    setCocktail({ ...cocktail, [event.target.name]: event.target.value });
  };

  const handleChangeByKey = (key, value) => {
    setCocktail({ ...cocktail, [key]: value });
  };

  const onSubmit = () => {
    let submittedCocktail = cocktail;

    const keywords = cocktail.keywords.split(",");
    submittedCocktail.keywords = keywords.map((s) => s.trim());

    if (submittedCocktail.id) {
      updateCocktail(submittedCocktail.id, submittedCocktail);
    } else {
      createCocktail(submittedCocktail);
    }
    closeEdit();
  };

  const handleGlasswareChange = (option) => {
    setCocktail({ ...cocktail, glassware: option });
  };

  useEffect(() => {
    const c = { ...cocktail, keywords: cocktail.keywords.join() };
    setCocktail(c);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="outer-wrapper">
      <div>
        <button className="general-button" onClick={closeEdit}>
          Close
        </button>
        <button className="general-button" onClick={handleClear}>
          Clear
        </button>
      </div>
      <div className="admin-cocktails-edit-wrapper">
        {/* Name - text input*/}
        <div className="admin-cocktails-edit-field">
          <label className="admin-cocktails-edit-label">Name</label>
          <input
            className="admin-cocktails-edit-text-input"
            type="text"
            name="name"
            placeholder="Cocktail Name"
            onChange={handleChange}
            value={cocktail.name}
          />
        </div>
        {/* Ingredients - Table input*/}
        <AdminCocktailIngredientEditV2
          cocktailIngredients={cocktail.ingredients}
          onChange={handleChangeByKey}
        />
        {/* Steps - list of strings (delimit period) */}
        <AdminCocktailTextToListEdit
          title="Steps"
          cocktailField="steps"
          existingValue={cocktail.steps || ""}
          handleChange={handleChangeByKey}
        />
        {/* Glassware - drop down */}
        <div className="admin-cocktails-edit-field">
          <Dropdown
            options={GLASSWARE}
            value={cocktail.glassware}
            onChange={handleGlasswareChange}
          />
        </div>
        {/* Image - Upload */}
        {/* TODO - https://codefrontend.com/file-upload-reactjs/#:~:text=In%20React%20file%20upload%20is,data%20to%20accept%20file%20uploads.*/}
        {/* Keywords - list of strings (delimit by spaces) */}
        <AdminCocktailTextToListEdit
          title="Keywords"
          cocktailField="keywords"
          existingValue={cocktail.keywords || ""}
          handleChange={handleChangeByKey}
          delimiter="comma"
        />
        <button
          className="general-button admin-button button-filled"
          onClick={onSubmit}
        >
          Save Cocktail
        </button>
      </div>
    </div>
  );
};

export default AdminCocktailEdit;
