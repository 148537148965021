import React from "react";
import ReactDOM from "react-dom/client";

import AppRouter from "./router/AppRouter";
import { CocktailsProvider } from "./contexts/cocktails";
import { ConfigProvider } from "./contexts/config";
import { TokenProvider } from "./contexts/token";

import "./styles/index.css";
import "./styles/cocktails.css";
import "./styles/admin.css";
import "./styles/login.css";

const element = document.getElementById("root");
const root = ReactDOM.createRoot(element);

const app = (
  <ConfigProvider>
    <TokenProvider>
      <CocktailsProvider>
        <AppRouter />
      </CocktailsProvider>
    </TokenProvider>
  </ConfigProvider>
);

root.render(app);
