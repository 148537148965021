const NotFoundPage = () => {
  return (
    <div>
      <h1>Whoopsie Daisy!</h1>
      <div>
        Only 6 year old girls with pigtails say that, and you! Since you're on
        the wrong page!
      </div>
    </div>
  );
};

export default NotFoundPage;
