import { Link } from "react-router-dom";
import { useConfigContext } from "../hooks/context";

const DashboardPage = () => {
  const { config, renderIntro, markIntroPlayed } = useConfigContext();

  const renderedCocktailsLink = (
    <div>
      <h1 className="general-header">Cocktails</h1>
      <div className="dashboard-cocktails-description">
        I also create craft cocktails, so if you're interested in my liquid
        delights feel free to head on over and check that out to!
      </div>
      <Link to="/cocktails">
        <div className="dashboard-cocktails-link general-button">
          Go to Cocktails!
        </div>
      </Link>
    </div>
  );

  const playIntro = () => {
    setTimeout(() => {
      markIntroPlayed();
    }, 4000);
    return renderIntro();
  };

  return (
    <div>
      <div>{playIntro()}</div>
      <div className="outer-wrapper">
        <div className="headshot-wrapper">
          <img
            className="headshot"
            src={`${process.env.PUBLIC_URL}/static/ice_glass_opener_no_background_cropped.png`}
            alt="headshot"
          />
        </div>
        <div className="text-content-wrapper dashboard-content">
          <p className="general-header">Hi there!</p>
          <p>I'm Devin.</p>
          <p>
            A Backend Software Engineer by day and a Cocktail Enthusiast by
            night. I like to build things and share them, so welcome, and thanks
            for dropping by!
          </p>
        </div>
        <div className="dashboard-extras">
          {config.showCocktails ? renderedCocktailsLink : undefined}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
