import AdminCocktailEdit from "../components/AdminCocktailEdit";

import Table from "../components/Table";
import { useState } from "react";
import { useCocktailsContext } from "../hooks/context";
import { EMPTY_COCKTAIL } from "../models";

// TODO - support setting `homemade`
const AdminCocktailPage = () => {
  const { cocktails, deleteCocktail } = useCocktailsContext();
  // -1 - Means closed
  const [openCocktailIndex, setOpenCocktailIndex] = useState(-1);
  const cocktailConfig = [
    {
      label: "Name",
      render: (cocktail) => cocktail.name,
    },
    {
      label: "Ingredients",
      render: (cocktail) => {
        let ingredients = "";
        cocktail.ingredients.map((ingredient) => {
          ingredients += ingredient.name + ", ";
          return ingredient.name;
        });
        // Trim and rip off the trailing comma
        return ingredients.trimEnd().substring(0, ingredients.length - 2);
      },
    },
    {
      label: "Edit",
      render: (_, index) => {
        return (
          <button
            className="general-button"
            onClick={() => {
              openEdit(index);
            }}
          >
            Edit
          </button>
        );
      },
    },
    {
      label: "Delete",
      render: (cocktail) => {
        return (
          <button
            className="general-button"
            onClick={() => {
              deleteCocktail(cocktail.id);
            }}
          >
            Delete
          </button>
        );
      },
    },
  ];

  const openEdit = (index) => {
    setOpenCocktailIndex(index);
  };

  const closeEdit = () => {
    setOpenCocktailIndex(-1);
  };

  const renderCocktails = () => {
    if (openCocktailIndex === -1) {
      return (
        <div className="outer-wrapper">
          <button
            className="general-button"
            onClick={() => {
              openEdit(cocktails.length);
            }}
          >
            Add New Cocktail
          </button>
          <div className="admin-cocktails-table">
            <Table config={cocktailConfig} data={cocktails} />
          </div>
        </div>
      );
    } else if (cocktails[openCocktailIndex]) {
      return (
        <AdminCocktailEdit
          incomingCocktail={cocktails[openCocktailIndex]}
          closeEdit={closeEdit}
        />
      );
    }
    return (
      <AdminCocktailEdit
        incomingCocktail={EMPTY_COCKTAIL}
        closeEdit={closeEdit}
      />
    );
  };

  return <div>{renderCocktails()}</div>;
};

export default AdminCocktailPage;
