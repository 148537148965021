import { Link, Outlet } from "react-router-dom";
import ContactInfo from "./ContactInfo";
import { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { MdArrowBackIos } from "react-icons/md";
import { useConfigContext, useTokenContext } from "../hooks/context";

const Navigation = ({ isProfessional }) => {
  const { config } = useConfigContext();
  const { isLoggedIn } = useTokenContext();
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const handleResumeClick = () => {
    window.open(`${process.env.PUBLIC_URL}/static/public_resume.pdf`);
  };
  const handleMobileNavClick = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const showMobileMenu = (menu) => {
    if (isMobileOpen) {
      return (
        <div>
          <div
            className="navigation-bar-mobile-wrapper"
            onClick={handleMobileNavClick}
          >
            <MdArrowBackIos
              className="navigation-bar-mobile-back-button"
              onClick={handleMobileNavClick}
            />
            {menu}
          </div>
          <div className="background" onClick={handleMobileNavClick}></div>
        </div>
      );
    } else {
      return (
        <div className="navigation-bar-mobile-button general-button">
          <AiOutlineMenu
            className="mobile-nav-button"
            onClick={handleMobileNavClick}
          />
        </div>
      );
    }
  };

  const PROFESSIONAL_MENU = (
    <nav>
      <ul className="link-ul">
        <li className="link-li">
          <Link to="/">Home</Link>
        </li>
        <li className="link-li">
          <Link to="/about">About</Link>
        </li>
        <li className="link-li">
          <Link to="/experience">Experience</Link>
        </li>
        <li className="link-li">
          <Link to="/projects">Projects</Link>
        </li>
        {config.showCocktails ? (
          <li className="link-li">
            <Link to="/cocktails">Cocktails</Link>
          </li>
        ) : undefined}
        <li className="link-li">
          <Link to="/contact">Contact</Link>
        </li>
        <li onClick={handleResumeClick} className="link-li link-li-button">
          <button>Résumé</button>
        </li>
      </ul>
    </nav>
  );

  const COCKTAILS_MENU = (
    <ul className="link-ul">
      <li className="link-li">
        <Link to="/">Home</Link>
      </li>
      <li className="link-li">
        <Link to="/cocktails">Cocktails</Link>
      </li>
      {isLoggedIn ? (
        <li className="link-li">
          <Link to="/cocktails/admin">Admin</Link>
        </li>
      ) : undefined}
      <li className="link-li">
        <Link to="/cocktails/login">Login</Link>
      </li>
    </ul>
  );

  if (isProfessional) {
    return (
      <div>
        {showMobileMenu(PROFESSIONAL_MENU)}
        <nav className="navigation-bar-full">{PROFESSIONAL_MENU}</nav>
        <ContactInfo />
        <Outlet />
      </div>
    );
  } else {
    return (
      <div>
        {showMobileMenu(COCKTAILS_MENU)}
        <nav className="navigation-bar-full">{COCKTAILS_MENU}</nav>
        <ContactInfo />
        <Outlet />
      </div>
    );
  }
};

export default Navigation;
