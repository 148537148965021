import { v4 as uuidv4 } from "uuid";

const Table = ({ config, data, classNames }) => {
  const renderedHeaders = config.map((entry) => {
    if (entry.hide === true) {
      return undefined;
    }
    return (
      <th key={uuidv4()}>{entry.header ? entry.header(entry) : entry.label}</th>
    );
  });

  const renderedRows = data.map((dataEntry, index) => {
    return (
      <tr key={uuidv4()}>
        {config.map((entry) => {
          if (entry.hide === true) {
            return undefined;
          }
          return (
            <td className="table-value" key={uuidv4()}>
              {entry.render(dataEntry, index)}
            </td>
          );
        })}
      </tr>
    );
  });

  let className = "table";
  if (classNames) {
    className = className + " " + classNames;
  }

  return (
    <table className={className}>
      <thead>
        <tr>{renderedHeaders}</tr>
      </thead>
      <tbody>{renderedRows}</tbody>
    </table>
  );
};

export default Table;
