import Modal from "./Modal";
import Dropdown from "./Dropdown";
import Table from "./Table";

import { useState } from "react";
import { EMPTY_INGREDIENT, UNITS } from "../models";
import { useMediaQuery } from "react-responsive";

const AdminCocktailIngredientEditV2 = ({ cocktailIngredients, onChange }) => {
  const [ingredientIndex, setIngredientIndex] = useState(-1);

  // This will make the table start clipping out side of the edit space during the 1150 - 1000
  // px range, so we'll just hide this field.
  const hideNotes = useMediaQuery({ query: `(max-width: 1150px)` });

  const handleChange = (event) => {
    const updatedIngredients = cocktailIngredients.map((ingredient, index) => {
      if (ingredientIndex === index) {
        return { ...ingredient, [event.target.name]: event.target.value };
      }
      return ingredient;
    });
    onChange("ingredients", updatedIngredients);
  };

  const handleUnitChange = (option) => {
    const updatedIngredients = cocktailIngredients.map((ingredient, index) => {
      if (ingredientIndex === index) {
        return { ...ingredient, unit: option };
      }
      return ingredient;
    });
    onChange("ingredients", updatedIngredients);
  };

  const handleHomemadeChange = (event) => {
    const updatedIngredients = cocktailIngredients.map((ingredient, index) => {
      if (ingredientIndex === index) {
        return { ...ingredient, [event.target.name]: event.target.checked };
      }
      return ingredient;
    });
    onChange("ingredients", updatedIngredients);
  };

  const closeModal = () => {
    setIngredientIndex(-1);
  };

  // Index of ingredients passed in, opens Modal on that ingredient
  const addNewIngredient = () => {
    //Indexes are 0 indexed so length will always be 1 ahead.
    setIngredientIndex(cocktailIngredients.length);
    // setIngredients([...ingredients, EMPTY_INGREDIENT]);
    onChange("ingredients", [...cocktailIngredients, EMPTY_INGREDIENT]);
  };

  const deleteIngredientByIndex = (index) => {
    const updatedIngredients = cocktailIngredients.filter((ingredient, i) => {
      return index !== i;
    });
    // setIngredients(updatedIngredients);
    onChange("ingredients", updatedIngredients);
  };

  const config = [
    {
      label: "Name",
      render: (ingredient) => ingredient.name,
    },
    {
      label: "Amount",
      render: (ingredient) => ingredient.amount,
    },
    {
      label: "Unit",
      render: (ingredient) => ingredient.unit,
    },
    {
      label: "Notes",
      hide: hideNotes,
      render: (ingredient) => ingredient.notes,
    },
    {
      label: "Homemade",
      render: (ingredient) => (
        <div className="ingredient-homade-status">
          {ingredient.homemade ? "✓" : "✗"}
        </div>
      ),
    },
    {
      label: "Edit",
      render: (_, index) => {
        return (
          <button
            className="general-button"
            onClick={() => {
              setIngredientIndex(index);
            }}
          >
            Edit
          </button>
        );
      },
    },
    {
      label: "Delete",
      render: (_, index) => {
        return (
          <button
            className="general-button"
            onClick={() => {
              deleteIngredientByIndex(index);
            }}
          >
            Delete
          </button>
        );
      },
    },
  ];

  const renderIngredientModal = () => {
    let ingredientToEdit = EMPTY_INGREDIENT;

    if (-1 === ingredientIndex) {
      return <div></div>;
    } else if (cocktailIngredients[ingredientIndex]) {
      ingredientToEdit = cocktailIngredients[ingredientIndex];
    }

    return (
      <Modal onClose={closeModal}>
        <div className="admin-cocktails-ingredient-modal-content-wrapper">
          <div className="admin-cocktails-ingredient-edit-header">
            <div className="admin-cocktails-ingredient-edit-header-text">
              Edit Ingredient
            </div>
            <div
              onClick={closeModal}
              className="general-button admin-cocktails-ingredient-edit-header-button"
            >
              X
            </div>
          </div>
          <div className="admin-cocktails-ingredient-edit-form">
            <div className="admin-cocktails-edit-field">
              <label className="admin-cocktails-ingredient-edit-label">
                Name
              </label>
              <input
                className="admin-cocktails-edit-text-input"
                type="text"
                name="name"
                placeholder="Name"
                onChange={handleChange}
                value={ingredientToEdit.name}
              />
            </div>
            <div className="admin-cocktails-edit-field">
              <label className="admin-cocktails-ingredient-edit-label">
                Amount
              </label>
              <input
                className="admin-cocktails-edit-text-input"
                type="text"
                name="amount"
                placeholder="Amount"
                onChange={handleChange}
                value={ingredientToEdit.amount}
              />
            </div>
            <div className="admin-cocktails-edit-field">
              <label className="admin-cocktails-ingredient-edit-label admin-cocktails-ingredient-unit-label">
                Unit
              </label>
              <Dropdown
                options={UNITS}
                value={ingredientToEdit.unit}
                onChange={handleUnitChange}
              />
            </div>
            <div className="admin-cocktails-edit-field">
              <label className="admin-cocktails-ingredient-edit-label">
                Homemade
              </label>
              <input
                className="admin-cocktails-edit-text-input"
                type="checkbox"
                name="homemade"
                onChange={handleHomemadeChange}
                checked={ingredientToEdit.homemade}
              />
            </div>
            <div className="admin-cocktails-edit-field">
              <label className="admin-cocktails-ingredient-edit-label">
                Notes
              </label>
              <input
                className="admin-cocktails-edit-text-input"
                type="text"
                name="notes"
                placeholder="Notes"
                onChange={handleChange}
                value={ingredientToEdit.notes}
              />
            </div>
            <button
              className="general-button admin-cocktails-ingredient-edit-button"
              onClick={closeModal}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      {renderIngredientModal()}
      <div className="admin-cocktails-edit-table">
        <div className="admin-cocktails-edit-ingredient-header">
          <label className="admin-cocktails-edit-label">Ingredients</label>
          <div className="general-button" onClick={addNewIngredient}>
            Add New Ingredient
          </div>
        </div>
        <Table
          classNames="admin-cocktails-table"
          config={config}
          data={cocktailIngredients}
        />
      </div>
    </div>
  );
};

export default AdminCocktailIngredientEditV2;
