import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import NotFoundPage from "../pages/NotFoundPage";
import DashboardPage from "../pages/DashboardPage";
import Navigation from "../components/Navigation";
import ContactPage from "../pages/ContactPage";
import ProjectsPage from "../pages/ProjectsPage";
import AboutPage from "../pages/AboutPage";
import ExperiencePage from "../pages/ExperiencePage";
import CocktailsPage from "../pages/CocktailsPage";
import AdminCocktailPage from "../pages/AdminCocktailPage";
import LoginPage from "../pages/LoginPage";

import { useCocktailsContext, useConfigContext } from "../hooks/context";

import emailjs from "@emailjs/browser";

function AppRouter() {
  const { config, fetchConfig } = useConfigContext();
  const { fetchCocktails } = useCocktailsContext();

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  useEffect(() => {
    fetchCocktails();
  }, [fetchCocktails]);

  emailjs.init(config.emailPubKey);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="cocktails" element={<Navigation isProfessional={false} />}>
          <Route index element={<CocktailsPage />} />
          <Route path="admin" element={<AdminCocktailPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="/" element={<Navigation isProfessional={true} />}>
          <Route index element={<DashboardPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="experience" element={<ExperiencePage />} />
          <Route path="projects" element={<ProjectsPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
