import { useCallback, useEffect, useState } from "react";
import { useTokenContext } from "../hooks/context";
import { useNavigate } from "react-router-dom";

const renderErrorBanner = (msg) => {
  return <div className="error-banner">Error: {msg}</div>;
};

const LoginPage = () => {
  const navigate = useNavigate();

  const { getToken } = useTokenContext();
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const [errorBanner, setErrorBanner] = useState(undefined);
  const [loggedIn, setLoggedIn] = useState(false);
  const [readyToLogin, setReadyToLogin] = useState(false);

  const handleChange = (event) => {
    setLoginData({ ...loginData, [event.target.name]: event.target.value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setReadyToLogin(true);
    return false;
  };

  const logIn = useCallback(
    async (data) => {
      const error = await getToken(data);
      if (error) {
        setErrorBanner(renderErrorBanner(error));
        setTimeout(() => {
          setErrorBanner(undefined);
        }, 5000);
      } else {
        setLoginData({
          username: "",
          password: "",
        });
        setLoggedIn(true);
      }
    },
    [getToken]
  );

  // Run these on every page reload
  useEffect(() => {
    if (readyToLogin) {
      logIn(loginData);
      setReadyToLogin(false);
    }
  }, [readyToLogin, logIn, loginData]);

  useEffect(() => {
    if (loggedIn) {
      navigate(-1);
    }
  }, [loggedIn, navigate]);

  return (
    <div>
      {errorBanner}
      <div className="outer-wrapper login-form-border">
        <form className="login-form" onSubmit={onSubmit}>
          <h1>Login</h1>
          <label>Username</label>
          <input
            type="text"
            name="username"
            placeholder="Username"
            onChange={handleChange}
            value={loginData.username}
          />
          <label>Password</label>
          <input
            type="password"
            name="password"
            placeholder="Password"
            onChange={handleChange}
            value={loginData.password}
          />
          <button className="general-button button-filled">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
