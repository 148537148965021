const EMPTY_COCKTAIL = {
  name: "",
  ingredients: [],
  steps: "",
  glassware: "",
  image: undefined,
  keywords: [],
};

const EMPTY_INGREDIENT = {
  name: undefined,
  amount: undefined,
  unit: undefined,
  notes: undefined,
  homemade: false,
};

const UNITS = [
  "oz",
  "tsp",
  "bar spoon",
  "rinse",
  "ml",
  "dash",
  "twist",
  "top up",
];

const GLASSWARE_MAPPING = {
  coupe: (
    <img
      className="cocktails-glassware"
      src={`${process.env.PUBLIC_URL}/static/glassware/coupe_v1.png`}
      alt="coupe-glass"
    />
  ),
  highball: (
    <img
      className="cocktails-glassware"
      src={`${process.env.PUBLIC_URL}/static/glassware/highball_v1.png`}
      alt="highball-glass"
    />
  ),
  pint: (
    <img
      className="cocktails-glassware"
      src={`${process.env.PUBLIC_URL}/static/glassware/pint_v1.png`}
      alt="pint-glass"
    />
  ),
  martini: (
    <img
      className="cocktails-glassware"
      src={`${process.env.PUBLIC_URL}/static/glassware/martini_v1.png`}
      alt="martini-glass"
    />
  ),
  rocks: (
    <img
      className="cocktails-glassware"
      src={`${process.env.PUBLIC_URL}/static/glassware/rocks_v1.png`}
      alt="rocks-glass"
    />
  ),
};

const GLASSWARE = Object.keys(GLASSWARE_MAPPING);

export {
  EMPTY_COCKTAIL,
  EMPTY_INGREDIENT,
  GLASSWARE,
  GLASSWARE_MAPPING,
  UNITS,
};
