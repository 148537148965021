import { useContext } from "react";

import CocktailsContext from "../contexts/cocktails";
import ConfigContext from "../contexts/config";
import TokenContext from "../contexts/token";

export const useCocktailsContext = () => {
  return useContext(CocktailsContext);
};

export const useConfigContext = () => {
  return useContext(ConfigContext);
};

export const useTokenContext = () => {
  return useContext(TokenContext);
};
