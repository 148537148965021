import { useState } from "react";
import { CgHello } from "react-icons/cg";
import emailjs from "@emailjs/browser";

import Modal from "../components/Modal";
import { isMobileQuery } from "../config";
import { useMediaQuery } from "react-responsive";
import { useConfigContext } from "../hooks/context";

const ContactPage = () => {
  const { config } = useConfigContext();

  const [showEmail, setShowEmail] = useState(false);
  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const isMobile = useMediaQuery({ query: isMobileQuery });

  const handleOpen = () => {
    setShowEmail(true);
  };

  const handleClose = () => {
    setShowEmail(false);
  };

  const handleEmailDataChange = (event) => {
    setEmailData({ ...emailData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const emailClientData = {
      from_name: emailData.name,
      message: emailData.message,
      reply_to: emailData.email,
      subject: emailData.subject,
    };
    emailjs.send("default_service", config.emailTemplateId, emailClientData);
    handleClose();
  };

  //TODO - cannot scroll on modal open desktop view squat screen size
  const EMAIL_FORM = (
    <div className="form-content-wrapper">
      <div>
        <button
          className="general-button contact-back-button"
          onClick={handleClose}
        >
          Back
        </button>
        <div></div>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="contact-form-field">
          <label>Name</label>
          <input
            className="contact-form-input"
            type="text"
            name="name"
            placeholder="Name"
            value={emailData.name}
            onChange={handleEmailDataChange}
          />
        </div>
        <div className="contact-form-field">
          <label>Your Email Address</label>
          <input
            className="contact-form-input"
            type="text"
            name="email"
            placeholder="Email Address"
            value={emailData.email}
            onChange={handleEmailDataChange}
          />
        </div>
        <div className="contact-form-field">
          <label>Subject</label>
          <input
            className="contact-form-input"
            type="text"
            name="subject"
            placeholder="Subject"
            value={emailData.subject}
            onChange={handleEmailDataChange}
          />
        </div>
        <div className="contact-form-field">
          <label>Email</label>
          <textarea
            className="contact-form-input"
            name="message"
            placeholder="Message Me!"
            value={emailData.message}
            onChange={handleEmailDataChange}
            rows="10"
            cols="50"
          />
        </div>
        <button className="contact-form-button general-button">Submit</button>
      </form>
    </div>
  );

  const renderEmailForm = () => {
    if (!showEmail) {
      return (
        <div className="outer-wrapper">
          {isMobile ? (
            <div className="contact-header">Contact Me</div>
          ) : (
            <CgHello className="contact-icon" />
          )}
          <div className="contact-description">
            Like what you see, want to collab, or just want to say hi? Shoot me
            an email! I'll do my best to get back to you ASAP!
          </div>
          <button className="general-button" onClick={handleOpen}>
            Email Me!
          </button>
        </div>
      );
    }
    if (isMobile) {
      return <div>{EMAIL_FORM}</div>;
    }
    return <Modal onClose={handleClose}>{EMAIL_FORM}</Modal>;
  };

  return <div>{renderEmailForm()}</div>;
};

export default ContactPage;
