import { v4 as uuidv4 } from "uuid";

import { useEffect, useRef, useState } from "react";
import { GoChevronDown } from "react-icons/go";

// These `value` and `onChange` names are a common convention even though they're horrid names.
const Dropdown = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const divEl = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (divEl.current && !divEl.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const cleanup = () => {
      document.removeEventListener("click", handler);
    };

    document.addEventListener("click", handler, true);
    return cleanup;
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setIsOpen(false);
    onChange(option);
  };

  const renderedOptions = options.map((option) => {
    return (
      <div
        key={uuidv4()}
        onClick={() => handleOptionClick(option)}
        className="dropdown-options"
      >
        {option}
      </div>
    );
  });

  const selection = value ? value : "Select...";

  return (
    <div ref={divEl} className="dropdown-wrapper" onClick={handleClick}>
      <div className="dropdown-closed">
        {selection}
        {/*<GoChevronDown className="text-lg" />*/}
        <GoChevronDown className="dropdown-carrot" />
      </div>
      {isOpen && <div>{renderedOptions}</div>}
    </div>
  );
};

export default Dropdown;
