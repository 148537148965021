import { createContext, useCallback, useState } from "react";
import { getConfig } from "../clients/backendClient";

const ConfigContext = createContext([]);

const ConfigProvider = ({ children }) => {
  const [playIntro, setPlayIntro] = useState(true);
  const [config, setConfig] = useState({});

  const fetchConfig = useCallback(async () => {
    const results = await getConfig();
    setConfig(results);
  }, []);

  const renderIntro = () => {
    if (playIntro) {
      return (
        <div>
          <div className="intro-wrapper">
            <img
              className="fade-out"
              src={`${process.env.PUBLIC_URL}/static/ice_glass_opener_v2_dark.gif`}
              alt="project"
            />
          </div>
          <div className="background intro-background"></div>
        </div>
      );
    }
    return undefined;
  };

  const markIntroPlayed = () => {
    setPlayIntro(false);
  };

  const contextValue = {
    config,
    fetchConfig,
    markIntroPlayed,
    renderIntro,
  };
  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigProvider };
export default ConfigContext;
