import { createContext, useEffect, useState } from "react";
import { postAuth } from "../clients/backendClient";
import { useConfigContext } from "../hooks/context";
import forge from "node-forge";

// loginData = {
//   username: "",
//   password: "",
// }

const importPublicKey = (base64PublicKey) => {
  const publicKeyPem = forge.util.decode64(base64PublicKey);
  return forge.pki.publicKeyFromPem(publicKeyPem);
};

const TokenContext = createContext([]);

const TokenProvider = ({ children }) => {
  const { config } = useConfigContext();
  const [pubkey, setPubKey] = useState(undefined);
  const [token, setToken] = useState(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [tokenReauth, setTokenReauth] = useState(undefined);
  // const [tokenExpiration, setTokenExpiration] = useState(undefined);
  // TODO - expire token, marked isLoggedIn false on expiration, route to Login Page
  //  and then back on success

  const generatePubkey = () => {
    if (config.authPublicKey) {
      const key = importPublicKey(config.authPublicKey);
      setPubKey(key);
    }
  };
  useEffect(generatePubkey, [config.authPublicKey]);

  const encrypt = (value) => {
    const encryptedValue = pubkey.encrypt(value, "RSA-OAEP", {
      md: forge.md.sha256.create(),
    });
    return forge.util.encode64(encryptedValue);
  };

  const getToken = async (loginData) => {
    const data = { ...loginData, password: encrypt(loginData.password) };
    return await postAuth(data)
      .then((r) => {
        setToken(r.authToken);
        setIsLoggedIn(true);
        return undefined;
      })
      .catch((err) => {
        setIsLoggedIn(false);
        return err.message;
      });
  };

  // TODO - Long term (if this is really annoying for me, or I add any other users to this) then figure
  //  out reAuth pattern, and store the token as a cookie.  As it is now - I don't care, and I can just
  //  keep on logging in.
  // const saveToken = () => {};
  // useEffect(saveToken, [token]);

  const contextValue = {
    token,
    isLoggedIn,
    getToken,
  };
  return (
    <TokenContext.Provider value={contextValue}>
      {children}
    </TokenContext.Provider>
  );
};

export { TokenProvider };
export default TokenContext;
