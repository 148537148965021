// const backendBaseUrl =
//   // "https://personal-site-backend-tq3x3wysfa-uc.a.run.app/api";
//   "http://localhost:9000/api";

// NOTE: React environment variables must start with `REACT_APP_`
const { REACT_APP_BACKEND_URL } = process.env;
const backendBaseUrl = REACT_APP_BACKEND_URL
  ? REACT_APP_BACKEND_URL
  : "http://localhost:9000/api";

// TODO - in future figure out a way to easily swap this for development
const isMobileQuery = `(max-width: 1200px)`;

const defaultEmailPublicKey = "dvrwZq9wlAD1xf4TF";
const defaultEmailTemplateId = "template_86t9jgl";

export {
  backendBaseUrl,
  isMobileQuery,
  defaultEmailPublicKey,
  defaultEmailTemplateId,
};
